import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { BkVM } from 'app/stores/ui/agt.sts.ui.store';

export const StsListe = observer(() => {
	const { berichteUiiStore, agtStsUiStore, agenturListUiStore } = useStore();
	const history = useHistory();
	const [hasItems, setHasItems] = useState(false);

	const tm = useMemo(() => {
		const tm = new TableModel<BkVM, number>();
		const cols: ITableColumn<BkVM, number>[] = [];

		cols.push({
			label: 'Agentur',
			path: 'agt.name',
			sortBy: 'agt.name',
			render: AgenturNameCell,
		});
		cols.push({
			label: 'Basic Schulung',
			path: 'basicSchulungAverage',
		});
		cols.push({
			label: 'Vorname ',
			path: 'bk.vorname',
		});

		cols.push({
			label: 'Nachname ',
			path: 'bk.nachname',
		});

		cols.push({
			label: 'bid ',
			path: 'bk.bid',
		});

		cols.push({
			label: 'Basic ',
			path: 'bk.basic',
		});

		cols.push({
			label: 'Mc ',
			path: 'bk.mc',
		});

		cols.push({
			label: 'Jahr ',
			path: 'bk.year',
		});

		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.idProperty = 'agtId';
		tm.idType = 'number';

		return tm;
	}, [history]);

	useEffect(() => {
		agtStsUiStore.load().then(() => {
			let items = agtStsUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as BkVM[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtStsUiStore.items, agtStsUiStore]);

	return (
		<>
			<div className="table-container pad-bottom-3rem">
				{!hasItems ? (
					<div className="pad-1rem">Keine KVM Teilnahme</div>
				) : (
					<>
						<Table stickyHeader={true} tm={tm}></Table>
					</>
				)}
			</div>
		</>
	);
});
