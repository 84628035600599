import { computed, makeObservable, observable, runInAction } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { ISelectOpt } from 'app/components/common/Select';
import { formatBM } from 'app/utils';
import { AgenturModel } from 'app/models/agentur.model';
import _ from 'lodash';
import { BaseUiStore } from './base.ui.store';
import { KvAgtMsLiteModel } from 'app/models/kv.agt.ms.lite.model';
import { KvAgtFacbookStore } from '../kv.agt.facebook.store';
import { KvAgtInstagramStore } from '../kv.agt.instagram.store';

export class InstagramBm {
	bm: number;
	url: string;
	status: number;
	follow: number;
	views: number;
	posts: number;
	aboPr: number;
	ownPosts: number;

	constructor(bm: number, url: string, status: number, follow: number, views: number, posts: number, aboPr: number, ownPosts: number) {
		this.bm = bm;
		this.url = url;
		this.status = status;
		this.follow = follow;
		this.views = views;
		this.posts = posts;
		this.aboPr = aboPr;
		this.ownPosts = ownPosts;
	}
}

export class FacebookBm {
	bm: number;
	url: string;
	status: number;
	fans: number;
	subs: number;
	posts: number;
	reach: number;
	views: number;
	aboPr: number;
	abo1890: number;
	ownPosts: number;

	constructor(bm: number, url: string, status: number, fans: number, subs: number, posts: number, reach: number, views: number, aboPr: number, abo1890: number, ownPosts: number) {
		this.bm = bm;
		this.url = url;
		this.status = status;
		this.fans = fans;
		this.subs = subs;
		this.posts = posts;
		this.reach = reach;
		this.views = views;
		this.abo1890 = abo1890;
		this.aboPr = aboPr;
		this.ownPosts = ownPosts;
	}
}

export class AgtSocialMediaVM {
	agt: AgenturModel;
	@observable
	fbs: FacebookBm[] = [];
	instas: InstagramBm[] = [];

	prevYearBm: number = 0;
	currYearBm: number = 0;
	currYear: number = 0;

	constructor(agt: AgenturModel, fbs: KvAgtMsLiteModel[], instas: KvAgtMsLiteModel[]) {
		makeObservable(this);
		this.agt = agt;
		const bms = [];
		for (const kv of fbs) {
			const j = kv.j;
			const kw = new FacebookBm(kv.bm, j.url, j.status, j.fans, j.subs, j.posts, j.reach, j.views, j.aboPr, j.abo1890, j.ownPosts);
			bms.push(kv.bm);
			this.fbs.push(kw);
		}
		for (const kv of instas) {
			const j = kv.j;
			const kw = new InstagramBm(kv.bm, j.url, j.status, j.follow, j.views, j.posts, j.aboPr, j.ownPosts);
			bms.push(kv.bm);
			this.instas.push(kw);
		}
		if (bms.length > 0) {
			this.currYearBm = _.max(bms)!;
			this.currYear = Math.round(this.currYearBm / 100);
			this.prevYearBm = (this.currYear - 1) * 100 + 12;
		}
	}

	@computed
	get prevFb() {
		return this.fbs.find((f) => f.bm === this.prevYearBm);
	}

	@computed
	get prevInsta() {
		return this.instas.find((f) => f.bm === this.prevYearBm);
	}

	@computed
	get currInsta() {
		return this.instas.find((f) => f.bm === this.currYearBm);
	}

	@computed
	get currFb() {
		return this.fbs.find((f) => f.bm === this.currYearBm);
	}

	@computed
	get prevFF() {
		return (this.prevFb ? this.prevFb.fans : 0) + (this.prevInsta ? this.prevInsta.follow : 0);
	}

	@computed
	get prevFFGreater250() {
		return this.prevFF >= 250 ? 1 : 0;
	}

	@computed
	get currFF() {
		return (this.currFb ? this.currFb.fans : 0) + (this.currInsta ? this.currInsta.follow : 0);
	}

	@computed
	get currFFGreater250() {
		return this.currFF >= 250 ? 1 : 0;
	}

	@computed
	get fbPostsYtd() {
		return this.fbs.reduce((acc, f) => {
			if (f.bm >= this.currYear * 100 && f.posts) {
				return acc + f.posts;
			}
			return acc;
		}, 0);
	}

	@computed
	get fbOwnPostsYtd() {
		return this.fbs.reduce((acc, f) => {
			if (f.bm >= this.currYear * 100 && f.ownPosts) {
				return acc + f.ownPosts;
			}
			return acc;
		}, 0);
	}

	@computed
	get fbViewsYtd() {
		return this.fbs.reduce((acc, f) => {
			if (f.bm >= this.currYear * 100 && f.views) {
				return acc + f.views;
			}
			return acc;
		}, 0);
	}

	@computed
	get instaPostsYtd() {
		return this.instas.reduce((acc, f) => {
			if (f.bm >= this.currYear * 100 && f.posts) {
				return acc + f.posts;
			}
			return acc;
		}, 0);
	}

	@computed
	get instaViewsYtd() {
		return this.instas.reduce((acc, f) => {
			if (f.bm >= this.currYear * 100 && f.views) {
				return acc + f.views;
			}
			return acc;
		}, 0);
	}

	@computed
	get instaOwnPostsYtd() {
		return this.instas.reduce((acc, f) => {
			if (f.bm >= this.currYear * 100 && f.ownPosts) {
				return acc + f.ownPosts;
			}
			return acc;
		}, 0);
	}
}

export class AgtSocialMediaUiStore extends BaseUiStore<AgtSocialMediaVM> {
	name = 'AgtFacebookUiStore';
	kvAgtFacebookStore: KvAgtFacbookStore;
	kvAgtInstagramStore: KvAgtInstagramStore;
	agenturStore: AgenturStore;

	constructor(agenturStore: AgenturStore, kvAgtFacebookStore: KvAgtFacbookStore, kvAgtInstagramStore: KvAgtInstagramStore) {
		super();
		makeObservable(this);
		this.kvAgtFacebookStore = kvAgtFacebookStore;
		this.kvAgtInstagramStore = kvAgtInstagramStore;
		this.agenturStore = agenturStore;
	}
	@observable
	bms: number[] = [];

	@observable
	minBmCurrent?: number;

	@observable
	maxBmCurrent?: number;

	@computed
	get currentYear() {
		const mb = this.maxBm;
		if (mb === undefined) {
			return '';
		}
		return Math.floor(mb / 100);
	}

	@computed
	get maxBm() {
		if (this.maxBmCurrent) return this.maxBmCurrent;
		return _.max(this.bms);
	}

	@computed
	get minBm() {
		if (this.minBmCurrent) return this.minBmCurrent;
		return _.min(this.bms);
	}

	@computed
	get bmOpts(): ISelectOpt[] {
		return this.bms.map((bm) => {
			return {
				value: bm,
				label: formatBM(bm),
			};
		});
	}

	@computed
	get currentBmItems() {
		if (!this.maxBm || !this.minBm) return this.items;
		const bmItems = this.items.filter((i) => i.currYearBm >= this.minBm! && i.currYearBm <= this.maxBm!);
		return bmItems;
	}

	async _baseLoad() {
		if (this.items.length > 0) {
			return;
		}

		const promises = await Promise.all([this.kvAgtFacebookStore.findAll(), this.kvAgtInstagramStore.findAll(), this.agenturStore.findAll()]);
		let fbs = promises[0];
		const instas = promises[1];
		const agts = promises[2];
		this.bms = _.uniq(_.map(fbs, 'bm')).sort();
		const res: AgtSocialMediaVM[] = [];
		for (const agt of agts) {
			let fb = fbs.filter((d) => d.agtId === agt.agtId);
			let insta = instas.filter((d) => d.agtId === agt.agtId);

			const sm = new AgtSocialMediaVM(agt, fb, insta);

			res.push(sm);
		}
		runInAction(() => {
			this.items = res;
		});
	}
}
