import { AgenturModel } from 'app/models/agentur.model';
import { AgenturStore } from '../agentur.store';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { ISelectOpt } from 'app/components/common/Select';
import { formatBM } from 'app/utils';
import _ from 'lodash';
import { BaseUiStore } from './base.ui.store';
import { KvAgtMsLiteModel } from 'app/models/kv.agt.ms.lite.model';
import { KvAgtKvmStore } from '../kv.agt.kvm.store';
import moment from 'moment';

export class KvmKw {
	kw: number;
	anzahlAnrufeGesamt: number;
	anzahlAnrufeWaehrendServicezeiten: number;
	ziel1Schaden: number;
	ziel2Vertragsanliegen: number;
	ziel3SonstigeAnliegen: number;
	ziel3DannRetoure: number;
	retoure: number;
	keineTaste: number;
	schnellauflege: number;

	constructor(kw: number, j: any) {
		this.kw = kw;
		this.anzahlAnrufeGesamt = j.a || 0;
		this.anzahlAnrufeWaehrendServicezeiten = j.asz || 0;
		this.ziel1Schaden = j.zs || 0;
		this.ziel2Vertragsanliegen = j.zv || 0;
		this.ziel3SonstigeAnliegen = j.za || 0;
		this.ziel3DannRetoure = j.zr || 0;
		this.retoure = j.r || 0;
		this.keineTaste = j.kt || 0;
		this.schnellauflege = j.sa || 0;
	}
}

export class KvmVM {
	agt: AgenturModel;
	@observable
	items: KvmKw[] = [];

	// make it easier to get the computeds
	[key: string]: any;

	constructor(agt: AgenturModel, kvs: KvAgtMsLiteModel[]) {
		makeObservable(this);
		this.agt = agt;

		for (const kv of kvs) {
			const j = kv.j ? kv.j : {};
			this.items.push(new KvmKw(kv.bm, j));
		}
	}

	@computed
	get anzahlAnrufeGesamtYtd() {
		return this._sumYtd('anzahlAnrufeGesamt');
	}

	_sumYtd(abo: string) {
		const year = moment().year();
		let f = this.items.filter((i) => i.kw >= year * 100);
		return _.sumBy(f, abo);
	}
}

export class AgtKvmUiStore extends BaseUiStore<KvmVM> {
	name = 'AgtKvmUiStore';
	kvAgtKvmStore: KvAgtKvmStore;
	agenturStore: AgenturStore;

	constructor(agenturStore: AgenturStore, kvAgtKvmStore: KvAgtKvmStore) {
		super();
		makeObservable(this);
		this.kvAgtKvmStore = kvAgtKvmStore;
		this.agenturStore = agenturStore;
	}
	@observable
	bms: number[] = [];

	@observable
	minBmCurrent?: number;

	@observable
	maxBmCurrent?: number;

	@computed
	get bmsOpts(): ISelectOpt[] {
		return this.bms.map((bm) => {
			return {
				value: bm,
				label: formatBM(bm),
			};
		});
	}

	@computed
	get maxBm() {
		if (this.maxBmCurrent) return this.maxBmCurrent;
		return _.max(this.bms);
	}

	@computed
	get minBm() {
		if (this.minBmCurrent) return this.minBmCurrent;
		return _.min(this.bms);
	}

	@computed
	get currentBmItems() {
		const kvVmItems: KvmVM[] = [];
		this.items.forEach((vm) => {
			const f = vm.items.filter((i) => {
				if (this.minBm && i.kw >= this.minBm) {
					if (this.maxBm && i.kw <= this.maxBm) {
						return true;
					}
				}
				return false;
			});
			const r = new KvmVM(vm.agt, []);
			runInAction(() => {
				r.items = f;
			});
			kvVmItems.push(r);
		});

		return kvVmItems;
	}

	async _baseLoad() {
		const data = await this.kvAgtKvmStore.findAll();
		const agts = await this.agenturStore.findAll();
		const res: KvmVM[] = [];
		for (const agt of agts) {
			const kvs = data.filter((d) => d.agtId === agt.agtId);
			if (kvs.length > 0) {
				const vm = new KvmVM(agt, kvs);
				res.push(vm);
			}
		}
		runInAction(() => {
			this.bms = _.uniq(_.map(data, 'bm')).sort();
			this.minBmCurrent = this.bms[0];
			this.maxBmCurrent = this.bms[this.bms.length - 1];
			this.items = res;
		});
	}
}
