import { AgenturModel } from 'app/models/agentur.model';
import { AgenturStore } from '../agentur.store';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import _ from 'lodash';
import { BaseUiStore } from './base.ui.store';
import { BkModel, KvAgtStsStore } from '../kv.agt.sts.store';

export class BkVM {
	agt: AgenturModel;
	@observable
	bk: BkModel[];

	constructor(agt: AgenturModel, bk: BkModel[]) {
		makeObservable(this);
		this.agt = agt;
		this.bk = bk;
	}

	@computed
	get basicSchulungAverage() {
		const res = this.bk.map((i) => i.basicPercent);
		const avg = _.mean(res);
		return avg;
	}
}

export class AgtStsUiStore extends BaseUiStore<BkVM> {
	name = 'AgtStsUiStore';
	kvAgtStsStore: KvAgtStsStore;
	agenturStore: AgenturStore;

	constructor(agenturStore: AgenturStore, kvAgtStsStore: KvAgtStsStore) {
		super();
		makeObservable(this);
		this.kvAgtStsStore = kvAgtStsStore;
		this.agenturStore = agenturStore;
	}

	getByAgtId(agtId: number): BkVM[] {
		return this.items.filter((i) => i.agt.agtId === agtId);
	}

	async _baseLoad() {
		const data = await this.kvAgtStsStore.load();
		const agts = await this.agenturStore.findAll();
		const res: BkVM[] = [];
		for (const agt of agts) {
			const kvs = data.filter((d) => d.agtId === agt.agtId);
			if (kvs.length > 0) {
				//kvs.forEach((kv) => {
				const vm = new BkVM(agt, kvs);
				res.push(vm);
				//});
			}
		}
		runInAction(() => {
			this.items = res;
		});
	}
}
