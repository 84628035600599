import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { AgtFitnessVm } from 'app/stores/ui/agt.fitness.ui.store';
import _ from 'lodash';
import { Button } from 'app/components/common/Button';

export const FitnessTrackerListeExport = observer(() => {
	const { berichteUiiStore, agtFitnessUiStore, agenturListUiStore } = useStore();
	const [hasItems, setHasItems] = useState(false);
	const [items, setItems] = useState([] as AgtFitnessVm[]);

	const tm = useMemo(() => {
		const tm = new TableModel<AgtFitnessVm, number>();
		const cols: ITableColumn<AgtFitnessVm, number>[] = [];

		cols.push(...agtBasicData);
		cols.push(...existsData);
		cols.push(...overviewData);
		cols.push(...googleData);
		cols.push(...amisNowData);
		cols.push(...akumaData);
		cols.push(...mazData);
		cols.push(...facebookData);
		cols.push(...instagramData);
		cols.push(...leadNowData);
		cols.push(...kvmData);
		cols.push(...stsData);

		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.idProperty = 'agtId';
		tm.idType = 'number';

		return tm;
	}, []);

	useEffect(() => {
		agtFitnessUiStore.load().then(() => {
			let items = agtFitnessUiStore.items;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtFitnessVm[];
			}
			setHasItems(items.length > 0);
			setItems(items);
			tm.setRowData(items);
			tm.sort();
		});
	}, [tm, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore, agtFitnessUiStore.items, agtFitnessUiStore]);

	const handleClick = () => {
		generateJson(items, tm.columns);
	};

	const generateJson = (items: AgtFitnessVm[], cols: ITableColumn<AgtFitnessVm, number>[]) => {
		const agtArr: any[] = [];

		items.forEach((item) => {
			const agtObj: any = {};
			cols.forEach((col) => {
				let val = _.get(item, col.path);
				const key = col.label.toString();
				if (col.path.includes('stsData')) {
					val = item.stsData;
				}
				if (col.path.includes('kvmMonthly')) {
					val = item.kvmMonthly ? item.kvmMonthly.map((kvmKw: any) => ({ bm: kvmKw.kw, anzahlAnrufeGesamt: kvmKw.anzahlAnrufeGesamt })) : null;
				}
				agtObj[key] = val;
			});
			agtArr.push(agtObj);
		});

		const json = JSON.stringify(agtArr);

		// create a file with the json data, trigger download
		const blob = new Blob([json], { type: 'application/json' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'fitness-tracker-export.json';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	return (
		<>
			{!hasItems ? (
				<div className="pad-1rem">Keine Fitnesstrackerdaten gefunden</div>
			) : (
				<>
					<Button onClick={handleClick}>Export JSON</Button>
					<Table stickyHeader={true} tm={tm} allowCsvExport={true} csvExportName="fitness-tracker-export.csv"></Table>
				</>
			)}
		</>
	);
});

// BASIC DATA
const agtBasicData = [
	{
		label: 'agtId',
		path: 'agt.agtId',
	},
	{
		label: 'agt',
		path: 'agt.name',
	},
	{
		label: 'vd',
		path: 'agt.vdId',
	},
	{
		label: 'gs',
		path: 'agt.gsId',
	},
];

// EXISTS DATA
const existsData = [
	{
		label: 'googleExists',
		path: 'googleExists',
	},
	{
		label: 'amisNowExists',
		path: 'amisNowExists',
	},
	{
		label: 'planerExists',
		path: 'planerExists',
	},
	{
		label: 'primeExists',
		path: 'primeExists',
	},
	{
		label: 'mazExists',
		path: 'mazExists',
	},
];

// OVERVIEW
const overviewData = [
	{
		label: 'gupZE',
		path: `gupAverage.ze`,
	},
	{
		label: 'amisNowAufgabenZE',
		path: `amisNowQuoteRead.ze`,
	},
	{
		label: 'planerAnzahlWochenNutzungZE',
		path: `planerAnzahlWochenNutzung.ze`,
	},
	{
		label: 'primeEinordnungZE',
		path: `primeEinordnungsquote.ze`,
	},
	{
		label: 'mazBestand',
		path: `mazBestandDurchdringungsquote.ze`,
	},
	{
		label: 'facebookFansZE',
		path: `fbFanAnzahl.ze`,
	},
	{
		label: 'instagramFollowerZE',
		path: 'instaFollower.ze',
	},
	{
		label: 'stsBasicSchulungAverageZE',
		path: 'stsBasicSchulungAverage',
	},
	{
		label: 'kvmInboundAnzahl',
		path: 'anzahlAnrufeGesamtYtd.ergebnis',
	},
	{
		label: 'leadNowBruttoLeadsAnzahl',
		path: 'leadNowBrutto.ergebnis',
	},
];

// GOOGLE
const googleData = [
	{
		label: 'googleRank',
		path: 'gup.rank',
	},
	{
		label: 'gupAnzahlBewertungen',
		path: 'anzBew.ergebnis',
	},
	{
		label: 'gupAnzahlBewertungenZiel',
		path: 'anzBew.ziel',
	},
	{
		label: 'gupBewertungDurchschnitt',
		path: `bewAvg.ergebnis`,
	},
	{
		label: 'gupBewertungDurchschnittZiel',
		path: `bewAvg.ziel`,
	},
	{
		label: 'gupMedia',
		path: `imgCount.ergebnis`,
	},
	{
		label: 'gupMediaZiel',
		path: `imgCount.ziel`,
	},
	{
		label: 'gupFA',
		path: `qCount.ergebnis`,
	},
	{
		label: 'gupFAZiel',
		path: `qCount.ziel`,
	},
].map((c) => ({
	...c,
	labelModifier: 'bg-green',
	cellModifier: 'bg-green',
}));

// AMIS NOW
const amisNowData = [
	{
		label: 'telefonieNachrichtVonKvmHalle',
		path: `amisNow.item.telefonieNachrichVonKvmHalle`,
	},
	{
		label: 'aufgabeAusAkuma',
		path: `amisNow.item.aufgabeAusAkuma`,
	},
	{
		label: 'kundennachrichtVertriebschance',
		path: `amisNow.item.kundennachrichtVertriebschance`,
	},
	{
		label: 'kuendigungsinformation',
		path: `amisNow.item.kuendigungsinformation`,
	},
	{
		label: 'aufgabeAusElektronischerPostkorb',
		path: `amisNow.item.aufgabeAusElektronischerPostkorb`,
	},
	{
		label: 'aufgabeAusAmisnowPostkorb',
		path: `amisNow.item.aufgabeAusAmisnowPostkorb`,
	},
	{
		label: 'freigabeAngebot',
		path: `amisNow.item.freigabeAngebot`,
	},
	{
		label: 'kundennachrichtServiceanliegen',
		path: `amisNow.item.kundennachrichtServiceanliegen`,
	},
	{
		label: 'meineAllianzNachricht',
		path: `amisNow.item.meineAllianzNachricht`,
	},
	{
		label: 'telefonieNachrichtVonKvmHalleInteressent',
		path: `amisNow.item.telefonieNachrichtVonKvmHalleInteressent`,
	},
	{
		label: 'kundennachrichtVertriebschanceWiederanlage',
		path: `amisNow.item.kundennachrichtVertriebschanceWiederanlage`,
	},
	{
		label: 'eigeneAufgaben',
		path: `amisNow.item.eigeneAufgaben`,
	},
	{
		label: 'summe',
		path: `amisNow.item.summe`,
	},
	{
		label: 'davonRead',
		path: `amisNow.item.davonRead`,
	},
	{
		label: 'quoteRead',
		path: `amisNowQuoteRead.ze`,
	},
].map((c) => ({
	...c,
	labelModifier: 'bg-blue',
	cellModifier: 'bg-blue',
}));

/// AKUMA
const akumaData = [
	//  --- isPrime ---
	{
		label: 'isAkumaPrimeUser',
		path: `isAkumaPrimeUser`,
	},
	//  --- PLANER ---
	{
		label: 'planerAnzahlWochenNutzung',
		path: `planerAnzahlWochenNutzung.ergebnis`,
	},
	{
		label: 'planerUebertragungZaehler',
		path: `planerUebertragungquoteAmisNow.ergebnis`,
	},
	{
		label: 'planerUebertragungNenner',
		path: `planerUebertragungquoteAmisNow.ziel`,
	},
	{
		label: 'planerUebertragungQuote',
		path: `planerUebertragungquoteAmisNow.ze`,
	},
	//  --- PRIME ---
	{
		label: 'primeEinordnungZaehler',
		path: `primeEinordnungsquote.ergebnis`,
	},
	{
		label: 'primeEinordnungNenner',
		path: `primeEinordnungsquote.ziel`,
	},
	{
		label: 'primeEinordnungQuote',
		path: `primeEinordnungsquote.ze`,
	},
	{
		label: 'primeAbwahlquoteZaehler',
		path: `primeAbwahlquote.ergebnis`,
	},
	{
		label: 'primeAbwahlquoteNenner',
		path: `primeAbwahlquote.ziel`,
	},
	{
		label: 'primeAbwahlQuote',
		path: `primeAbwahlquote.ze`,
	},
	{
		label: 'primeTerminierungExternZaehler',
		path: 'primeTerminierungExtern.ergebnis',
	},
	{
		label: 'primeTerminierungExternNenner',
		path: `primeTerminierungExtern.ziel`,
	},
	{
		label: 'primeTerminierungExternQuote',
		path: 'primeTerminierungExtern.ze',
	},
].map((c) => ({
	...c,
	labelModifier: 'bg-yellow',
	cellModifier: 'bg-yellow',
}));

/// Meine Allianz
const mazData = [
	{
		label: 'mazBestand',
		path: `mazBestandDurchdringungsquote.ergebnis`,
	},
	{
		label: 'mazBestandZiel',
		path: `mazBestandDurchdringungsquote.ziel`,
	},
	{
		label: 'mazNeugeschaeft',
		path: `mazNeuDurchdringungsquote.ergebnis`,
	},
	{
		label: 'mazNeugeschaeftZiel',
		path: `mazNeuDurchdringungsquote.ziel`,
	},
].map((c) => ({
	...c,
	labelModifier: 'bg-blue',
	cellModifier: 'bg-blue',
}));

// Social Media
const facebookData = [
	{
		label: 'fbStatus',
		path: 'fbStatus',
	},
	{
		label: 'fbFanAnzahl',
		path: 'fbFanAnzahl.ergebnis',
	},
	{
		label: 'fbPostinganzahl',
		path: 'fbPostinganzahl.ergebnis',
	},
	{
		label: 'fbEigenePosts',
		path: 'fbEigenePosts.ergebnis',
	},
	{
		label: 'fbPRAbo',
		path: 'fbPRAbo',
	},
	{
		label: 'fb1890Abo',
		path: 'fb1890Abo',
	},
];

const instagramData = [
	{
		label: 'instaStatus',
		path: 'instaStatus',
	},
	{
		label: 'instaFollower',
		path: 'instaFollower.ergebnis',
	},
	{
		label: 'instaPostinganzahl',
		path: 'instaPostinganzahl.ergebnis',
	},
	{
		label: 'instaEigenePosts',
		path: 'instaEigenePosts.ergebnis',
	},
	{
		label: 'instaPRAbo',
		path: 'instaPRAbo',
	},
];

// Leadnow
const leadNowData = [
	{
		label: 'leadNowBrutto',
		path: 'leadNowBrutto.ergebnis',
	},
	{
		label: 'leadNowNetto',
		path: 'leadNowNetto.ergebnis',
	},
	{
		label: 'leadNowStorno',
		path: 'leadNowStorno.ergebnis',
	},
	{
		label: 'leadNowAv',
		path: 'leadNowAv.ergebnis',
	},
	{
		label: 'leadNowBaufi',
		path: 'leadNowBaufi.ergebnis',
	},
	{
		label: 'leadNowBausp',
		path: 'leadNowBausp.ergebnis',
	},
	{
		label: 'leadNowBu',
		path: 'leadNowBu.ergebnis',
	},
	{
		label: 'leadNowFsach',
		path: 'leadNowFsach.ergebnis',
	},
	{
		label: 'leadNowKfz',
		path: 'leadNowKfz.ergebnis',
	},
	{
		label: 'leadNowKv',
		path: 'leadNowKv.ergebnis',
	},
	{
		label: 'leadNowKzus',
		path: 'leadNowKzus.ergebnis',
	},
	{
		label: 'leadNowPsach',
		path: 'leadNowPsach.ergebnis',
	},
	{
		label: 'leadNowReise',
		path: 'leadNowReise.ergebnis',
	},
	{
		label: 'leadNowTier',
		path: 'leadNowTier.ergebnis',
	},
	{
		label: 'leadNowBerat',
		path: 'leadNowBerat.ergebnis',
	},
];

// KVS
const kvmData = [
	{
		label: 'kvmAnrufeGesamtYtd',
		path: 'anzahlAnrufeGesamtYtd.ergebnis',
	},
	{
		label: 'kvmMonthly',
		path: 'kvmMonthly',
		render: (row: any) => {
			const d = row.data.kvmMonthly;
			return d ? (
				<>
					{d.map((kvmKw: any) => {
						return (
							<span key={`${row.data.agt.agtId}_${kvmKw.kw}`}>
								{kvmKw.kw} | {kvmKw.anzahlAnrufeGesamt}
								<br />
							</span>
						);
					})}
				</>
			) : (
				<></>
			);
		},
	},
];

// STS
const stsData = [
	{
		label: 'stsData',
		path: 'stsData.bk',
		render: (row: any) => {
			const d = row.data.stsData;
			return d ? (
				<>
					{d.map((bk: any) => {
						return (
							<span key={bk.bid}>
								{bk.bid} | basic: {bk.basic} | mc: {bk.mc} | y: {bk.year}
								<br />
							</span>
						);
					})}
				</>
			) : (
				<></>
			);
		},
	},
];
