import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];




posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Hardwareadministration im Verantwortungsbereich',
		planKey: 'steigerung_kundenvertrauen_i',
		einheit: Einheit.quali,
		posDesc: `Nachhaltiges und wirtschaftliches Handeln bei administrativen Tätigkeiten im Bereich Hardware
im Verantwortungsbereich. Planung und Durch-
führung von praktikablen Arbeitsmustern,
insbesondere bei der Bestellung und dem
Abbau von Hardware.`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Vertreteraktivierung in den Wachstumsdimensionen',
		planKey: 'vertreteraktivierung',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und Ansprechpartner:in für Führungskräfte innerhalb der VD für den
strategischen Einsatz aller digitalen und analogen
Instrumente der Wachstumsdimensionen.
Verantwortung für die Nutzung und Etablierung
der Instrumente durch gezielte Einsteuerung von
Neuerungen und Best Practices.
Austausch über laufendes Feedback an
betreuenden Fachabteilungen.`,
	}),
);


posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Agenturprozesse und Agenturadministratio',
		planKey: 'agtprozesse',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und Ansprechpartner:in im Verant- wortungsbereich für die Gestaltung effizienter
Agenturabläufe und -prozesse, Verantwortung
für die Etablierung von neuen Anwendungen
durch gezielte Einsteuerung von Neuerungen
und Best-Practices.`,
	}),
);


posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: 10010,
		group: 'max8k',
		posText: 'Sichtbar sein digital persönlich  - Steigerung der digitalen Sichtbarkeit I',
		planKey: 'sichtbarsein_gup',
		einheit: Einheit.proz,
		boniStufen: 2,
		posDesc: `Steigerung der Anzahl GV/HV mit mehr als 25 Google Bewertungen im Verantwortungsbereich Stichtag der Bemessungsgrundlage für das Jahresziel ist der GV/HV Bestand zum 01.01.2025`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: 10020,
		group: 'max8k',
		posText: 'Sichtbar sein digital persönlich  - Steigerung der digitalen Sichtbarkeit II',
		planKey: 'sichtbarsein_smb',
		einheit: Einheit.proz,
		boniStufen: 2,
		posDesc: `Steigerung der Anzahl GV/HV mit mehr als 250 Fans/Followern im Verantwortungsbereich Stichtag der Bemessungsgrundlage für das Jahresziel ist der GV/HV Bestand zum 01.01.2025 `,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Jeden Kontakt nutzen - Unterstützung und Abschluss der Umstellung auf AMIS.NOW',
		planKey: 'amisnow',
		einheit: Einheit.quali,
		posDesc: ` Multiplikator:in und Ansprechpartner:in für Führungskräfte über die strategische und fachliche
Ausrichtung des Vertriebsarbeitsplatzes.
Verantwortung für die Etablierung von
Kommunikations- und Schulungskonzepten.
Abschluss der Umstellung auf AMIS.NOW
aller GV/HV im Verantwortungsbereich.
Förderung von Verkaufschancen durch Etablierung
von AMIS.NOW Aufgaben in den Arbeitsalltag
der Agenturen im Verantwortungsbereich.`,
	}),
);


posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Jeden Kontakt nutzen - Förderung von Vertriebschancen im Agenturteam',
		planKey: 'servicetosale',
		einheit: Einheit.quali,
		posDesc: ` Multiplikator:in und Ansprechpartner:in für Service-to-Sale. Förderung von Vertriebschancen
im gesamten Agenturteam durch Einsteuerung
und Nachhalten geeigneter Maßnahmen im
Verantwortungsbereich`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: 10030,
		group: 'max10k',
		posText: 'Jeden Kunden kontaktieren - Steigerung der Cross-Selling-Quote und Kundenbindung',
		planKey: 'akuma',
		einheit: Einheit.proz,
		posDesc: `Anzahl GV/HV mit wiederkehrender AKUMA Nutzung in der VD plus Anzahl GV/HV an AKUMA.Prime. (wiederkehrende AKUMA Planer Nutzung von mindestens 12 Wochen im Jahr 2025) Stichtag der Bemessungsgrundlage für das
Jahresziel ist der GV/HV Bestand zum 01.01.2025`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef, // wird geliefert
		group: 'max10k',
		posText: 'Immer da sein - Neuanmeldungen Meine Allianz',
		planKey: 'maz',
		einheit: Einheit.proz,
		posDesc: `Steigerung der Durchdringung von Kunden mit Meine Allianz im Bestand Basis Jahresanfangsbestand 01.01.2025`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Immer da sein - Öffnungszeiten effektiv verlängern',
		planKey: 'openinghours',
		einheit: Einheit.quali,
		posDesc: `Förderung und Multiplikation von Service- leistungen im Bereich Inbound. Förderung von
Erreichbarkeit im Verantwortungsbereich durch
Einsteuerung und Nachhalten geeigneter
Maßnahmen.`,
	}),
);


posDef.push(
	new BnrGpPosModel({
		year: 2025,
		posId: 10040,
		group: 'max10k',
		posText: 'Neue Kunden finden - Förderung der Neukundengewinnung',
		planKey: 'leadnow',
		einheit: Einheit.proz,
		posDesc: `Steigerung der Neukundengewinnung mit Lead.NOW. Anzahl teilnehmender Agenturen im Verantwortungsbereich. Bemessungsgrundlage für das Jahresziel
ist der GV/HV Bestand zum 01.01.2025`,
	}),
);



export const EdpPositionen = posDef;
