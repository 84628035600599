import _ from 'lodash';
import { SessionStore } from './session.store';
import { KvAgtModel } from 'app/models/kv.agt.model';
import { KvAgtBaseStore } from './kv.agt.base.store';

export class BkModel {
	constructor(item: KvAgtModel, j: any) {
		this.agtId = item.agtId;
		this.bid = j.bid ? j.bid : '';
		this.vorname = j.v ? j.v : '';
		this.nachname = j.n ? j.n : '';
		this.basic = j.basic ? j.basic : 0;
		this.mc = j.mc ? j.mc : 0;
		this.year = j.y ? j.y : 0;
		this.basicPercent = Math.round((this.basic / 14) * 100);
	}

	agtId: number;
	bid: number;
	year: number = 0;
	vorname: string;
	nachname: string;
	basic: number;
	mc: number;
	basicPercent: number;
}

export class KvAgtStsStore {
	kvAgtStore: KvAgtBaseStore;

	constructor(session: SessionStore) {
		this.kvAgtStore = new KvAgtBaseStore(session);
		this.kvAgtStore.apiPath = 'api/kv_agt/sts';
	}

	findByAgtId(agtId: number) {
		return this.items.find((i) => i.agtId === agtId);
	}

	items: BkModel[] = [];

	async load() {
		if (this.items.length > 0) {
			return this.items;
		}
		const data = await this.kvAgtStore.findAll();
		const res: any[] = [];
		data.forEach((item) => {
			const j = JSON.parse(item.valueString);
			j.forEach((element: any) => {
				res.push(new BkModel(item, element));
			});
		});
		this.items = res;
		return this.items;
	}
}
